
import { defineComponent, PropType } from 'vue';
import { useI18n } from 'vue-i18n';
import { Pie } from './charts';
import { SalesDataItem } from '@/api/dashboard/analysis';

export type SalesType = 'all' | 'online' | 'stores' | string;

export default defineComponent({
  props: {
    loading: {
      type: Boolean,
      default: () => false,
    },
    salesType: {
      type: String as PropType<SalesType>,
      required: true,
      validator: function (value: SalesType) {
        return ['all', 'online', 'stores'].indexOf(value) !== -1;
      },
    },
    salesPieData: {
      type: Array as PropType<SalesDataItem[]>,
      default: () => [],
    },
    handleChangeSalesType: {
      type: Function as PropType<(e?: Event) => void>,
      default: () => null,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  components: {
    Pie,
  },
});
