import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-40324bd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "salesCardExtra" }
const _hoisted_2 = { class: "salesTypeRadio" }
const _hoisted_3 = { style: {"margintop":"8px","marginbottom":"32px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_pie = _resolveComponent("pie")!
  const _component_a_card = _resolveComponent("a-card")!

  return (_openBlock(), _createBlock(_component_a_card, {
    class: "salesCard",
    style: {"height":"100%"},
    loading: _ctx.loading,
    bordered: false,
    title: _ctx.t('dashboard.analysis.the-proportion-of-sales')
  }, {
    extra: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "dropdownGroup", {}, undefined, true),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_a_radio_group, {
            value: _ctx.salesType,
            onChange: _ctx.handleChangeSalesType
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_radio_button, { value: "all" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('dashboard.analysis.channel.all')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_radio_button, { value: "online" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('dashboard.analysis.channel.online')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_radio_button, { value: "stores" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t('dashboard.analysis.channel.stores')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["value", "onChange"])
        ])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.t('dashboard.analysis.sales')), 1),
        _createVNode(_component_pie, {
          data: _ctx.salesPieData,
          height: 248
        }, null, 8, ["data"])
      ])
    ]),
    _: 3
  }, 8, ["loading", "title"]))
}